import Vue from 'vue'
import VueLoading from 'vuejs-loading-plugin'

// using default options
Vue.use(VueLoading)

// overwrite defaults
// Vue.use(VueLoading, {
//   dark: true, // default false
//   loading: true, // default false
//   customLoader: myVueComponent, // replaces the spinner and text with your own
//   background: 'rgb(255,255,255)', // set custom background
//   classes: ['myclass'] // array, object or string
// })