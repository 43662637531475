import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    path: "/edit-profile",
    name: "Profile",
    component: () => import("@/views/EditProfile.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("@/views/SignUpPage.vue"),
  },
  {
    path: "/otp",
    name: "InputOtp",
    component: () => import("@/views/InputOtp.vue"),
  },
  {
    path: "/homepage",
    name: "Home",
    component: () => import("@/views/HomePage.vue"),
  },
  // {
  //   path: "/auth",
  //   name: "Auth",
  //   component: () => import("@/views/AuthPage.vue"),
  //   children: [
  //     {
  //       path: "register",
  //       name: "AuthRegister",
  //       component: () => import("@/views/auth/")
  //     }
  //   ],
  // },
  // {
  //   path: "/account",
  //   name: "AccountProfile",
  //   component: () => import("@/views/Account.vue"),
  //   redirect: { name: 'AccountProfile' },
  //   children: [
  //     {
  //       path: "profile",
  //       name: "AccountProfile",
  //       component: () => import("@/views/account/AccountProfile.vue")
  //     },
  //   ]
  // },
]

const router = new VueRouter({
  mode: 'history',
  // base: 'localhost:8080',
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //     return { x: 0, y: 0, behavior: 'smooth' };
  // }
});

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

export default router;