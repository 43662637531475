import Vue from 'vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from '../store';

const apiV1 = axios.create({
    baseURL: `https://bmwidgateway.com/api/api/v1`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });

apiV1.interceptors.request.use(request => {
  // return request;
  request.headers.Authorization = `${store.state.token}`;
  return request;
});

Vue.prototype.$apiV1 = apiV1;

Vue.use(VueAxios, axios);