import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';

Vue.config.productionTip = false

Vue.config.devtools = true

import '@/plugins/axios.js';
import '@/plugins/vue-loading.js';
import '@/plugins/toast-notification.js';
import '@/assets/css/style.css';
import '@/assets/css/font.css';
import 'boxicons/css/boxicons.min.css';

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
