import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

const ls = new SecureLS({
    isCompression: false,
    encodingType: 'aes',
    encryptionSecret: 'XB3etb52tshlodMDylvOeVvu4NqgpEZg'
});

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        appLoading: true,
        user: {},
        token: "",
        name: "",
        phone_number: null
    },
    getters: {
        isLoggedIn(state) {
            return state.token != "";
        },
    },
    mutations: {
        setAppLoading(state, payload) {
            state.appLoading = payload;
        },
        setUser(state, payload) {
            state.user = payload;
        },
        setName(state, payload) {
            state.name = payload;
        },
        setToken(state, payload) {
            state.token = payload;
        },
        setPhone(state, payload) {
            state.phone_number = payload;
        },
    },
    actions: {
        login({ commit }, payload) {
            commit('setToken', payload.token);
        },
        logout({ commit }) {
            commit('setToken', "");
        },
        async authenticateUser({ getters, dispatch }) {
            if (!getters.isLoggedIn) {
                dispatch('logout');
            }
        }
    },
    plugins: [
        createPersistedState({
            paths: ['token'],
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ]
});
